import React from "react"
import {CgMenuGridO} from "react-icons/cg";
import {AiOutlineLineChart, AiOutlineVideoCamera} from "react-icons/ai";
import {IoDocumentSharp} from "react-icons/io5";
import {IoIosPaper} from "react-icons/io";
import {BsWallet2} from "react-icons/bs";

const navigationConfig = [
    {
        id: "Summary",
        title: "Summary",
        type: "item",
        icon: <CgMenuGridO size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/"
    },
    {
        id: "Performance",
        title: "Performance",
        type: "item",
        icon: <AiOutlineLineChart size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/Performance"
    },
    {
        id: "Documents",
        title: "Documents",
        type: "item",
        icon: <IoDocumentSharp size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/Documents"
    },
    {
        id: "Articles",
        title: "Articles",
        type: "item",
        icon: <IoIosPaper size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/Articles"
    },
    {
        id: "meetings",
        title: "Meetings",
        type: "item",
        icon: <AiOutlineVideoCamera size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/meetings"
    },
    {
        id: "Wallet",
        title: "Wallet",
        type: "item",
        icon: <BsWallet2 size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/Wallet"
    },

]

export default navigationConfig
