import React from "react";
import {Link} from "react-router-dom";
import Lang from "./lang";
import {NavItem, NavLink} from "reactstrap";
import {IoNotificationsOutline} from "react-icons/io5";
import Customizer from "../../../components/@vuexy/customizer/Customizer";
import {connect} from "react-redux";
import {
    changeFooterType,
    changeMenuColor, changeMode,
    changeNavbarColor,
    changeNavbarType, hideScrollToTop
} from "../../../redux/actions/customizer";
import themeConfig from "../../../configs/themeConfig";




class NavbarUser extends React.PureComponent {
    state = {
        width: window.innerWidth,
        sidebarState: false,
        layout: this.props.app.customizer.theme,
        collapsedContent: false,
        sidebarHidden: false,
        currentLang: "en",
        appOverlay: false,
        customizer: false,
        isTouropen: false,
        menuOpen: themeConfig.menuOpen,
    }
    handleCustomizer = bool => {
        this.setState({
            customizer: bool
        })
    }

    componentDidMount() {
        let layout = this.props.app.customizer.theme
        return layout === "dark"
            ? document.body.classList.add("dark-layout")
            : layout === "semi-dark"
                ? document.body.classList.add("semi-dark-layout")
                : null
    }
    componentDidUpdate() {

        let layout = this.props.app.customizer.theme
        if (layout === "dark") {
            document.body.classList.remove("semi-dark-layout")
            document.body.classList.add("dark-layout")
        } else if (layout === "semi-dark") {
            document.body.classList.remove("dark-layout")
            document.body.classList.add("semi-dark-layout")
        } else {
             document.body.classList.remove("dark-layout", "semi-dark-layout")
            document.body.classList.add("light")

        }
    }

    handleAppOverlay = value => {
        if (value.length > 0)
            this.setState({
                appOverlay: true
            })
        else if (value.length > 0 || value === "") {
            this.setState({
                appOverlay: false
            })
        }
    }


    render() {
        let customizerProps = this.props.app.customizer
        return (
            <ul className="nav navbar-nav navbar-nav-user float-right">
                <div className="nav-item d-flex flex-row justify-content-between align-items-center">

                        <Customizer
                            scrollToTop={customizerProps.hideScrollToTop}
                            activeNavbar={customizerProps.navbarColor}
                            activeMode={customizerProps.theme}
                            navbarType={customizerProps.navbarType}
                            footerType={customizerProps.footerType}
                            menuTheme={customizerProps.menuTheme}
                            customizerState={this.state.customizer}
                            handleCustomizer={this.handleCustomizer}
                            changeNavbar={this.props.changeNavbarColor}
                            changeNavbarType={this.props.changeNavbarType}
                            changeFooterType={this.props.changeFooterType}
                            changeMenuTheme={this.props.changeMenuColor}
                            hideScrollToTop={this.props.hideScrollToTop}
                            changeMode={this.props.changeMode}
                   />
                </div>
                <Link to="/Notifications">
                <NavItem className="nav-item d-block">
                    <NavLink  className="mx-2" style={{marginTop:"4%"}}>
                        <div style={{position:"relative"}}>
                        <div className="Notify"></div>
                        <IoNotificationsOutline size={25} />
                        </div>
                    </NavLink>
                </NavItem>
            </Link>
                <Lang props={this.props.app.customizer.theme}/>
            </ul>
        );
    }
}
const mapStateToProps = state => {
    return {
        app: state.customizer
    }
}
export default connect(mapStateToProps, {
    changeNavbarColor,
    changeNavbarType,
    changeFooterType,
    changeMenuColor,
    hideScrollToTop,
    changeMode
})(NavbarUser)